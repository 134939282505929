const mediaContainer = {
  ISOBMFF: 'ISOBMFF',
  MP4: 'MP4',
  PIFF: 'PIFF',
  TS: 'TS',
};

const assetTypeOptions = {
  Blooper: 'ASSET/SHORTFORM/BLOOPER',
  Clip: 'ASSET/SHORTFORM/CLIP',
  Episode: 'ASSET/EPISODE',
  Featurette: 'ASSET/SHORTFORM/FEATURETTE',
  Narrative: 'ASSET/SHORTFORM/NARRATIVE',
  Presentation: 'ASSET/SHORTFORM/PRESENTATION',
  Press: 'ASSET/SHORTFORM/PRESS',
  Programme: 'ASSET/PROGRAMME',
  Promotion: 'ASSET/SHORTFORM/PROMOTION',
  Teaser: 'ASSET/SHORTFORM/TEASER',
  Theatrical: 'ASSET/SHORTFORM/THEATRICAL',
  episode: 'ASSET/EPISODE',
  programme: 'ASSET/PROGRAMME',
};

const playbackType = {
  Advert: 'Advert',
  Clip: 'Clip',
  FER: 'FER',
  Live: 'Live',
  Preview: 'Preview',
  SingleLiveEvent: 'SingleLiveEvent',
  VOD: 'VOD',
};

const ovp = {
  DrmType: {
    Fairplay: 'FPS',
    Marlin: 'MS3',
    None: 'NONE',
    PlayReady: 'PLAYREADY',
    Widevine: 'WIDEVINE',
  },
  StreamTransport: {
    DASH: 'DASH',
    HLS: 'HLS',
    HSS: 'HSS',
    PDL: 'PDL',
    PSF: 'PSF',
  },
};

export const streamConfigPresets = {
  NEWS_NOW: {
    initializationOptions: {
      appBrand: 'nbcnews',
      appName: 'nbcnews',
    },
    streamConfig: {
      playbackType: playbackType.Live,
      serviceKey: '5676009166762707117',
      assetMetadata: {
        channelId: 'NBC News Now',
        channelName: 'nbc_news_now',
        programmeId: 'NBC News Now',
        programmeTitle: 'nbc_news_now',
        duration: 0,
      },
      assetByType: {
        hlsCBCS: {
          transport: ovp.StreamTransport.HLS,
          container: mediaContainer.ISOBMFF,
          adCompatibility: { encodingProfile: 'cmaf-hls-30fps-NBCS-Oneapp-v1' },
          url: 'https://live-oneapp-prd-news.akamaized.net/Content/CMAF_OL1-CBC-4s/Live/channel(nnn)/master.m3u8',
        },
        dash: {
          transport: ovp.StreamTransport.DASH,
          container: mediaContainer.ISOBMFF,
          adCompatibility: {
            encodingProfile: 'cmaf-dash-30fps-NBCS-Oneapp-v1',
          },
          url: 'https://live-oneapp-prd-news.akamaized.net/Content/CMAF_OL1-CTR-4s/Live/channel(nnn)/master.mpd',
        },
      },
    },
  },
  MSNBC_TVE: {
    initializationOptions: {
      appBrand: 'msnbc',
      appName: 'nbcnews',
    },
    streamConfig: {
      playbackType: playbackType.Live,
      serviceKey: '5608086763860174117',
      assetMetadata: {
        channelId: 'MSNBC',
        channelName: 'msnbc',
        programmeId: 'MSNBC',
        programmeTitle: 'msnbc',
        duration: 0,
      },
      assetByType: {
        hlsCBCS: {
          transport: ovp.StreamTransport.HLS,
          container: mediaContainer.ISOBMFF,
          adCompatibility: { encodingProfile: 'cmaf-hls-30fps-NBCS-Oneapp-v1' },
          url: 'https://live-oneapp-prd-news.akamaized.net/Content/CMAF_OL1-CBC-4s/Live/channel(msnbc)/master.m3u8',
        },
        dash: {
          transport: ovp.StreamTransport.DASH,
          container: mediaContainer.ISOBMFF,
          adCompatibility: {
            encodingProfile: 'cmaf-dash-30fps-NBCS-Oneapp-v1',
          },
          url: 'https://live-oneapp-prd-news.akamaized.net/Content/CMAF_OL1-CTR-4s/Live/channel(msnbc)/master.mpd',
        },
      },
    },
  },
  TODAY_ALL_DAY: {
    initializationOptions: {
      appBrand: 'nbcnews',
      appName: 'nbcnews',
    },
    streamConfig: {
      playbackType: playbackType.Live,
      serviceKey: '7989219137174638117',
      assetMetadata: {
        channelId: 'Today',
        channelName: 'today_all_day',
        programmeId: 'Today',
        programmeTitle: 'today_all_day',
        duration: 0,
      },
      assetByType: {
        hlsCBCS: {
          transport: ovp.StreamTransport.HLS,
          container: mediaContainer.ISOBMFF,
          adCompatibility: { encodingProfile: 'cmaf-hls-30fps-NBCS-Oneapp-v1' },
          url: 'https://live-oneapp-prd-news.akamaized.net/Content/CMAF_OL2-CBC-4s/Live/channel(todayallday)/master.m3u8',
        },
        dash: {
          transport: ovp.StreamTransport.DASH,
          container: mediaContainer.ISOBMFF,
          adCompatibility: {
            encodingProfile: 'cmaf-dash-30fps-NBCS-Oneapp-v1',
          },
          url: 'https://live-oneapp-prd-news.akamaized.net/Content/CMAF_OL2-CTR-4s/Live/channel(todayallday)/master.mpd',
        },
      },
    },
  },
  CNBC: {
    initializationOptions: {
      appBrand: 'cnbc',
      appName: 'cnbc',
    },
    streamConfig: {
      playbackType: playbackType.Live,
      serviceKey: '5724738528402108117',
      assetMetadata: {
        channelId: 'CNBC',
        channelName: 'cnbc',
        programmeId: 'CNBC',
        programmeTitle: 'cnbc',
        duration: 0,
        brand: 'CNBC',
      },
      assetByType: {
        hlsCBCS: {
          transport: ovp.StreamTransport.HLS,
          container: mediaContainer.ISOBMFF,
          adCompatibility: { encodingProfile: 'cmaf-hls-30fps-NBCS-Oneapp-v1' },
          url: 'https://live-oneapp-prd.akamaized.net/Content/CMAF_OL1-CBC-4s/Live/channel(cnbc)/master.m3u8',
        },
        dash: {
          transport: ovp.StreamTransport.DASH,
          container: mediaContainer.ISOBMFF,
          adCompatibility: {
            encodingProfile: 'cmaf-dash-30fps-NBCS-Oneapp-v1',
          },
          url: 'https://live-oneapp-prd-news.akamaized.net/Content/CMAF_OL1-CTR-4s/Live/channel(cnbc)/master.mpd',
        },
      },
    },
  },
  CNBC_PRO: {
    initializationOptions: {
      appBrand: 'cnbcpro',
      appName: 'cnbc',
    },
    streamConfig: {
      playbackType: playbackType.Live,
      serviceKey: '5724738528402108117',
      assetMetadata: {
        channelId: 'CNBC Pro',
        channelName: 'cnbc_pro',
        programmeId: 'CNBC Pro',
        programmeTitle: 'cnbc_pro',
        duration: 0,
        brand: 'CNBC',
      },
      assetByType: {
        hlsCBCS: {
          transport: ovp.StreamTransport.HLS,
          container: mediaContainer.ISOBMFF,
          adCompatibility: { encodingProfile: 'cmaf-hls-30fps-NBCS-Oneapp-v1' },
          url: 'https://live-oneapp-prd-news.akamaized.net/Content/CMAF_OL1-CBC-4s-v2/Live/channel(cnbc)/master_30min.m3u8',
        },
        dash: {
          transport: ovp.StreamTransport.DASH,
          container: mediaContainer.ISOBMFF,
          adCompatibility: {
            encodingProfile: 'cmaf-dash-30fps-NBCS-Oneapp-v1',
          },
          url: 'https://live-oneapp-prd-news.akamaized.net/Content/CMAF_OL1-CBC-4s-v2/Live/channel(cnbc)/master_30min.mpd',
        },
      },
    },
  },
  CNBC_GLOBAL: {
    initializationOptions: {
      appBrand: 'cnbcplus',
      appName: 'cnbc',
    },
    streamConfig: {
      playbackType: playbackType.Live,
      serviceKey: '',
      assetMetadata: {
        channelId: '',
        channelName: '',
        programmeId: '',
        programmeTitle: '',
        duration: 0,
        brand: '',
      },
      assetByType: {
        hlsCBCS: {
          url: 'https://live-oneapp-prd-news.akamaized.net/Content/CMAF_OL1-CBC-4s-v2/Live/channel(cnbcpwog)/master_30min.m3u8',
          transport: ovp.StreamTransport.HLS,
          container: mediaContainer.ISOBMFF,
          adCompatibility: { encodingProfile: 'cmaf-hls-30fps-NBCS-Oneapp-v1' },
        },
        dash: {
          url: 'https://live-oneapp-prd-news.akamaized.net/Content/CMAF_OL1-CTR-4s-v2/Live/channel(cnbcpwog)/master_30min.mpd',
          transport: ovp.StreamTransport.DASH,
          container: mediaContainer.ISOBMFF,
          adCompatibility: {
            encodingProfile: 'cmaf-dash-30fps-NBCS-Oneapp-v1',
          },
        },
      },
    },
  },
  CNBC_DATA: {
    initializationOptions: {
      appBrand: 'cnbc',
      appName: 'cnbc',
    },
    streamConfig: {
      playbackType: playbackType.Live,
      adsEnabled: false,
      serviceKey: '',
      assetMetadata: {
        channelId: '',
        channelName: '',
        programmeId: '',
        programmeTitle: '',
        duration: 0,
        brand: '',
      },
      assetByType: {
        hlsCBCS: {
          url: 'https://live-oneapp-prd-news.akamaized.net/Content/CMAF_OL1-CBC-4s-v2/Live/channel(cnbcpwithg)/master_30min.m3u8',
          transport: ovp.StreamTransport.HLS,
          container: mediaContainer.ISOBMFF,
          adCompatibility: { encodingProfile: 'cmaf-hls-30fps-NBCS-Oneapp-v1' },
        },
        dash: {
          url: 'https://live-oneapp-prd-news.akamaized.net/Content/CMAF_OL1-CTR-4s-v2/Live/channel(cnbcpwithg)/master_30min.mpd',
          transport: ovp.StreamTransport.DASH,
          container: mediaContainer.ISOBMFF,
          adCompatibility: {
            encodingProfile: 'cmaf-dash-30fps-NBCS-Oneapp-v1',
          },
        },
      },
    },
  },
  CNBC_FULLEPISODE: {
    initializationOptions: {
      appBrand: 'cnbc',
      appName: 'cnbc',
    },
    streamConfig: {
      playbackType: 'VOD',
      serviceKey: '',
      assetMetadata: {
        channelId: 'CNBC FULL EPISODE',
        channelName: 'cnbc_full_episode',
        programmeId: '',
        programmeTitle: '',
        duration: 0,
        brand: 'CNBC',
        type: assetTypeOptions.Episode,
      },
      adsEnabled: {
        midRollAdEnabled: true,
        preRollAdEnabled: true,
      },
      assetByType: {
        hlsCBCS: {
          url: '',
          transport: ovp.StreamTransport.HLS,
          container: mediaContainer.ISOBMFF,
          adCompatibility: { encodingProfile: 'not_required' },
        },
        dash: {
          url: '',
          transport: ovp.StreamTransport.DASH,
          container: mediaContainer.ISOBMFF,
          adCompatibility: { encodingProfile: 'not_required' },
        },
      },
    },
  },
  NOTICIAS_AHORA: {
    initializationOptions: {
      appBrand: 'nbcnews',
      appName: 'nbcnews',
    },
    streamConfig: {
      playbackType: playbackType.Live,
      // DRM-related
      keyId: 'nota',
      serviceKey: '7536064712744877117',
      assetMetadata: {
        channelId: 'Noticias Telemundo Ahora',
        channelName: 'noticias_telemundo_ahora',
        programmeId: 'Noticias Telemundo Ahora',
        programmeTitle: 'noticias_telemundo_ahora',
        duration: 0,
      },
      assetByType: {
        hlsCBCS: {
          transport: ovp.StreamTransport.HLS,
          container: mediaContainer.ISOBMFF,
          adCompatibility: { encodingProfile: 'cmaf-hls-30fps-NBCS-Oneapp-v1' },
          url: 'https://live-oneapp-prd-news.akamaized.net/Content/CMAF_OL2-CBC-4s/Live/channel(nota)/master.m3u8',
        },
        dash: {
          transport: ovp.StreamTransport.DASH,
          container: mediaContainer.ISOBMFF,
          adCompatibility: {
            encodingProfile: 'cmaf-dash-30fps-NBCS-Oneapp-v1',
          },
          url: 'https://live-oneapp-prd-news.akamaized.net/Content/CMAF_OL2-CTR-4s/Live/channel(nota)/master.mpd',
        },
      },
    },
  },
  DATELINE: {
    initializationOptions: {
      appBrand: 'nbcnews',
      appName: 'nbcnews',
    },
    streamConfig: {
      playbackType: playbackType.Live,
      // DRM-related
      keyId: 'dateline',
      serviceKey: '7509458937090982117',
      assetMetadata: {
        channelId: 'Dateline',
        channelName: 'dateline',
        programmeId: 'Dateline',
        programmeTitle: 'dateline_247',
        duration: 0,
      },
      assetByType: {
        hlsCBCS: {
          transport: ovp.StreamTransport.HLS,
          container: mediaContainer.ISOBMFF,
          adCompatibility: { encodingProfile: 'cmaf-hls-30fps-NBCS-Oneapp-v1' },
          url: 'https://live-oneapp-prd-news.akamaized.net/Content/CMAF_OL2-CBC-4s/Live/channel(dateline)/master.m3u8',
        },
        dash: {
          transport: ovp.StreamTransport.DASH,
          container: mediaContainer.ISOBMFF,
          adCompatibility: {
            encodingProfile: 'cmaf-dash-30fps-NBCS-Oneapp-v1',
          },
          url: 'https://live-oneapp-prd-news.akamaized.net/Content/CMAF_OL2-CTR-4s/Live/channel(dateline)/master.mpd',
        },
      },
    },
  },
  SKY_NEWS: {
    initializationOptions: {
      appBrand: 'nbcnews',
      appName: 'nbcnews',
    },
    streamConfig: {
      playbackType: playbackType.Live,
      // DRM-related
      keyId: 'skynews',
      serviceKey: '4718718353051323117',
      assetMetadata: {
        channelId: 'SkyNews',
        channelName: 'sky_news',
        programmeId: 'SkyNews',
        programmeTitle: 'skynews',
        duration: 0,
      },
      assetByType: {
        hlsCBCS: {
          transport: ovp.StreamTransport.HLS,
          container: mediaContainer.ISOBMFF,
          adCompatibility: { encodingProfile: 'cmaf-hls-30fps-NBCS-Oneapp-v1' },
          url: 'https://live-oneapp-prd-news.akamaized.net/Content/CMAF_OL2-CBC-4s/Live/channel(skynews)/master.m3u8',
        },
        dash: {
          transport: ovp.StreamTransport.DASH,
          container: mediaContainer.ISOBMFF,
          adCompatibility: {
            encodingProfile: 'cmaf-dash-30fps-NBCS-Oneapp-v1',
          },
          url: 'https://live-oneapp-prd-news.akamaized.net/Content/CMAF_OL2-CTR-4s/Live/channel(skynews)/master.mpd',
        },
      },
    },
  },
  TELEMUNDO_AL_DIA: {
    initializationOptions: {
      appName: 'nbcnews',
      appBrand: 'telemundo',
    },
    streamConfig: {
      serviceKey: '4743269032877988117',
      playbackType: playbackType.Live,
      // DRM-related
      keyId: 'telemundoaldia',
      assetMetadata: {
        channelId: 'Telemundo Al Dia',
        channelName: 'telemundo_al_dia',
        programmeId: 'Telemundo Al Dia',
        programmeTitle: 'telemundo_al_dia',
        duration: 0,
      },
      assetByType: {
        hlsCBCS: {
          transport: ovp.StreamTransport.HLS,
          container: mediaContainer.ISOBMFF,
          adCompatibility: { encodingProfile: 'cmaf-hls-30fps-NBCS-Oneapp-v1' },
          url: 'https://live-oneapp-prd.akamaized.net/Content/CMAF_OL2-CBC-4s/Live/channel(telemundoaldia)/master.m3u8',
        },
        dash: {
          transport: ovp.StreamTransport.DASH,
          container: mediaContainer.ISOBMFF,
          adCompatibility: {
            encodingProfile: 'cmaf-dash-30fps-NBCS-Oneapp-v1',
          },
          url: 'https://live-oneapp-prd.akamaized.net/Content/CMAF_OL2-CTR-4s/Live/channel(telemundoaldia)/master.mpd',
        },
      },
    },
  },
};
